// deps
import { useCallback, useEffect, useState } from "react";
import Router from "next/router";
import { Box, Progress as ChakraProgress } from "@chakra-ui/react";

export default function Progress() {
  const [visible, setVisible] = useState(false);

  const start = useCallback(function () {
    setVisible(true);
  }, []);

  const stop = useCallback(function () {
    setVisible(false);
  }, []);

  useEffect(
    function () {
      Router.events.on("routeChangeStart", start);
      Router.events.on("routeChangeComplete", stop);
      Router.events.on("routeChangeError", stop);

      return function () {
        Router.events.off("routeChangeStart", start);
        Router.events.off("routeChangeComplete", stop);
        Router.events.off("routeChangeError", stop);
      };
    },
    [start, stop],
  );

  return (
    <Box pos="fixed" zIndex={13} top={0} right={0} left={0} h="0.3125rem">
      <ChakraProgress
        pointerEvents="none"
        colorScheme="red"
        isIndeterminate={visible}
        opacity={visible ? 1 : 0}
        transition="opacity .25s ease-in-out"
        size="xs"
      />
    </Box>
  );
}
