/**
 * Redirige l’utilisateur vers une autre page avec une méthode HTTP particulière en utilisant un formulaire.
 * @param {object} param0
 * @param {string} param0.url
 * @param {string} [param0.method="GET"]
 * @param {object} [param0.data={}]
 */
export default function formRedirect({ url, method = "get", data = {} }) {
  const form = document.createElement("form");
  document.body.appendChild(form);
  form.method = method;
  form.action = url;
  Object.entries(data).forEach(([name, value]) => {
    const input = document.createElement("input");
    input.type = "hidden";
    input.name = name;
    input.value = value;
    form.appendChild(input);
  });
  form.submit();
}
