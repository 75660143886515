// deps
import { MenuItem, MenuList, useToast, Link } from "@chakra-ui/react";
import { useRouter } from "next/router";
import { useIntl } from "react-intl";
import NextLink from "next/link";

// hooks
import useApiFetcher from "@raiden/library-ui/hooks/useApiFetcher";
import useAuth from "@raiden/library-ui/hooks/useAuth";
import useCanPerform from "@raiden/library-ui/hooks/useCanPerform";

// libraries
import generateApiUri from "@raiden/library-ui/libraries/utils/generateApiUrl";
import generateAdminPath from "@raiden/library-ui/libraries/utils/generateAdminPath";
import generateExtranetUrl from "@raiden/library-ui/libraries/utils/generateExtranetUrl";
import generateKioscUrl from "@raiden/library-ui/libraries/utils/generateKioscUrl";

// constants
import {
  AUTHORIZATIONS_AUTHORIZATION_VALUE_LEGACY_EXTRANET,
  AUTHORIZATIONS_AUTHORIZATION_VALUE_EXTERNAL_KIOSC,
} from "@raiden/library-ui/constants/authorizations";

export default function UserMenu(props) {
  const { mutate } = useAuth();

  const intl = useIntl();

  const router = useRouter();

  const toast = useToast();

  const apiFetcher = useApiFetcher();

  const canPerform = useCanPerform();

  /**
   * Gère le clic sur le bouton de déconnexion
   */
  function handleClickLogout() {
    apiFetcher(
      generateApiUri({
        id: "@auth.logout",
      }),
      {
        method: "POST",
      },
    ).then(function () {
      toast({
        id: "logout",
        status: "success",
        title: intl.formatMessage({
          defaultMessage: "Déconnexion réussie",
        }),
        position: "bottom-right",
      });

      router.push({ pathname: generateAdminPath({ id: "login" }) });

      mutate();
    });
  }

  return (
    <MenuList>
      <NextLink
        href={generateAdminPath({ id: "@usersAdmins.me" })}
        passHref={true}>
        <MenuItem as="a" color="white">
          {intl.formatMessage({
            defaultMessage: "Mon compte",
          })}
        </MenuItem>
      </NextLink>

      {canPerform({
        authorizations: AUTHORIZATIONS_AUTHORIZATION_VALUE_LEGACY_EXTRANET,
      }) && (
        <Link isExternal href={generateExtranetUrl({ id: "@extranet" })}>
          <MenuItem color="white">
            {intl.formatMessage({
              defaultMessage: "Extranet",
            })}
          </MenuItem>
        </Link>
      )}

      {canPerform({
        authorizations: AUTHORIZATIONS_AUTHORIZATION_VALUE_EXTERNAL_KIOSC,
      }) && (
        <Link isExternal href={generateKioscUrl({ id: "@kiosc.login" })}>
          <MenuItem color="white">
            {intl.formatMessage({
              defaultMessage: "Kiosc",
            })}
          </MenuItem>
        </Link>
      )}

      <MenuItem onClick={handleClickLogout} color="red.500">
        {intl.formatMessage({
          defaultMessage: "Me déconnecter",
        })}
      </MenuItem>
    </MenuList>
  );
}
