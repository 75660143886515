// deps
import { useColorMode } from "@chakra-ui/react";
import getBasePath from "@splitfire-agency/raiden-library/dist/libraries/utils/getBasePath";
import cookie from "cookie";
import dayjs from "dayjs";
import { useRouter } from "next/router";
import PropTypes from "prop-types";
import {
  createContext,
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { COOKIES_NAME_VALUE_PAGINATION_PER_PAGE } from "../../constants/cookies";
import { useConfiguration } from "../../hooks/useConfiguration";
import generateAdminPath from "../../libraries/utils/generateAdminPath";
import usePreviousColorMode from "../usePreviousColorMode";

/**
 * @typedef {object} PreferencesContextValue
 * @property {number} paginationPerPage
 * @property {(value: number) => void} setPaginationPerPage
 * @property {import("@raiden/library-ui/types/Configuration").ConfigurationEnvironment | null} bestEnvironment
 * @property {boolean} shouldRenderEnvironments
 */

/** @type {PreferencesContextValue} */
const DEFAULT_VALUE = {
  paginationPerPage: 25,
  setPaginationPerPage: () => {},
  bestEnvironment: null,
  shouldRenderEnvironments: false,
};

export const PreferencesContext = createContext(DEFAULT_VALUE);

const { Provider } = PreferencesContext;

/**
 * permet de recuperer le pathname d'une url
 * si on utilise pathname on perd la valeur des param de l'url type id
 * @param {string} url
 * @returns {string}
 */
function getPathname(url) {
  return url?.split("?")[0];
  // on supprimer les query param qui sont dynamiques
}

/**
 * @param {{
 * initialPaginationPerPage: any,
 * children: import("react").ReactNode,
 * }} props
 */
function _PreferencesProvider(props) {
  const { initialPaginationPerPage, children } = props;

  const {
    configuration: { environments },
  } = useConfiguration();

  const paginationPerPage = useRef(Number(initialPaginationPerPage));
  const shouldRenderEnvironments = (environments?.length ?? 0) > 1;

  const bestEnvironment = useMemo(() => {
    return environments?.length === 1 ? environments[0] : null;
  }, [environments]);

  const { colorMode, setColorMode } = useColorMode();
  const { previousColorMode, setPreviousColorMode } = usePreviousColorMode();

  const { asPath, query } = useRouter();

  /**
   * Liste des url qui doivent forcer le theme sur light
   * ces urls sont des pages qui incluent des composants provenant du legacy de la console
   * @param {object} param0
   * @param {object} param0.query
   * @returns {string[]}
   */
  function getUrlList({ query }) {
    return [
      generateAdminPath({
        id: "@room.update",
        parameters: {
          hotelId: query?.hotelId,
        },
      }),
      generateAdminPath({
        id: "@cartsBookingsLegacy.viewAny",
      }),
      generateAdminPath({
        id: "@carts.view",
        parameters: {
          cartId: query?.id,
        },
      }),
    ];
  }

  /**
   * Liste des url qui doivent forcer le theme sur light
   * ces urls sont des pages qui incluent des composants provenant du legacy de la console
   * @param {object} param0
   * @param {string} param0.url
   * @param {object} param0.query
   * @returns {boolean}
   */
  const isUrlInclude = useCallback(({ url, query }) => {
    const urlList = getUrlList({ query });
    return urlList?.some(function (item) {
      return url === item;
    });
  }, []);

  /**
   * Force le theme sur light si besoin
   */
  const forcedThemeToLight = useCallback(() => {
    if (colorMode === "dark") {
      setPreviousColorMode(colorMode);
      setColorMode("light");
    }
  }, [colorMode, setColorMode, setPreviousColorMode]);

  /**
   * reset le theme  si besoin
   */
  const resetThemeToPrevious = useCallback(() => {
    const previousColor = previousColorMode();
    if (previousColor && colorMode !== previousColor) {
      setColorMode(previousColor);
    }
  }, [colorMode, setColorMode, previousColorMode]);

  useEffect(() => {
    if (isUrlInclude({ url: getPathname(asPath), query })) {
      forcedThemeToLight();
    } else {
      resetThemeToPrevious();
    }
  }, [forcedThemeToLight, asPath, resetThemeToPrevious, query, isUrlInclude]);

  /* je laisse ce bout de code en commentaire au cas ou, j'ai loupé quelque chose dans mon analyse */
  /* je pense que le useEffect précédent est suffisant pour traiter les chamgements de theme */
  /*useEffect(() => {
    const handleRouteChange = (url) => {
      if (isUrlInclude({ url: getPathname(url), query })) {
        forcedThemeToLight();
      } else {
        resetThemeToPrevious();
      }
    };

    events.on("routeChangeComplete", handleRouteChange);

    return () => {
      events.off("routeChangeComplete", handleRouteChange);
    };
  }, [
    events,
    colorMode,
    setColorMode,
    forcedThemeToLight,
    previousColorMode,
    resetThemeToPrevious,
  ]);*/

  const value = useMemo(
    function () {
      /** @type {PreferencesContextValue} */
      const value = {
        paginationPerPage: paginationPerPage.current,
        setPaginationPerPage(newPaginationPerPage) {
          document.cookie = cookie.serialize(
            COOKIES_NAME_VALUE_PAGINATION_PER_PAGE,
            String(newPaginationPerPage),
            {
              expires: dayjs().add(1, "years").toDate(),
              path: getBasePath(process.env.NEXT_PUBLIC_ADMIN_BASE_PATH ?? ""),
              secure: true,
            },
          );

          paginationPerPage.current = newPaginationPerPage;
        },
        shouldRenderEnvironments,
        bestEnvironment,
      };
      return value;
    },
    [bestEnvironment, shouldRenderEnvironments],
  );

  return <Provider value={value}>{children}</Provider>;
}

_PreferencesProvider.displayName = "PreferencesProvider";

_PreferencesProvider.propTypes = {
  initialPaginationPerPage: PropTypes.any,
  children: PropTypes.node,
};

export const PreferencesProvider = memo(_PreferencesProvider);

export const usePreferences = function () {
  return useContext(PreferencesContext);
};
