export default function usersGetNicename({ resource }) {
  let nicename = undefined;

  if (resource?.first_name) {
    nicename = resource.first_name;
  }

  if (resource?.last_name) {
    nicename = nicename
      ? `${nicename} ${resource.last_name}`
      : resource.last_name;
  }

  return nicename;
}
