import { PHASE_PRODUCTION_BUILD } from "next/constants";
import { serverApiFetcher } from "../../../hooks/useApiFetcher";
import generateApiUri from "../../../libraries/utils/generateApiUrl";

/**
 * @typedef {object} NextGetUserReturn
 * @property {import("../../../types/User").UserBase} [user]
 * @property {import("../../../types/Api/ApiError").ApiError} [error]
 */

/**
 * Récupère les informations sur l’utilisateur courant.
 * @param {object} param0
 * @param {object} param0.cookies
 * @param {import("@splitfire-agency/raiden-library/dist/libraries/Uri").default} param0.baseUri
 * @param {import("http").IncomingMessage} [param0.req] Requête initiale de la page
 * @param {string} [param0.locale]
 * @returns {Promise<NextGetUserReturn>}
 */
export default async function nextGetUser({ cookies, baseUri, locale, req }) {
  let error;

  if (process.env.NEXT_PHASE === PHASE_PRODUCTION_BUILD) {
    return {
      user: undefined,
      error: undefined,
    };
  }

  /** @type {import("../../../types/ApiResponse").ApiResponse<import("../../../types/User").UserBase | []> | undefined} */
  const response = await serverApiFetcher(
    generateApiUri({
      id: "@auth.me",
      query: {
        fields: ["users.admins.avatar"],
      },
    }),
    undefined,
    {
      cookies,
      origin: baseUri.toString(),
      locale,
      req,
    },
  ).catch(function (tempError) {
    error = tempError;
  });

  return {
    user: Array.isArray(response?.data) ? undefined : response?.data,
    error,
  };
}
