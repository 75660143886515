// deps
import { forwardRef } from "react";

// components
import EnvironmentSelect from "../EnvironmentSelect";

// context
import { useGuards } from "../../contexts/Guards";
import { useConfiguration } from "@raiden/library-ui/hooks/useConfiguration";

// hooks
import useCanPerform from "@raiden/library-ui/hooks/useCanPerform";

/**
 * Filtre les environnements en fonction d'une authorisation
 * @typedef {object} Params
 * @property {import("@raiden/library-ui/types/Configuration").ConfigurationEnvironment[]} [environments]
 * @property {string | Array<string> | null} authorizations
 * @property {(data: any) => boolean} canPerform
 */

/**
 * @param {Params} props
 * @returns {Array}
 */
function getListEnvironmentRelayOnAuthorizations({
  environments,
  authorizations,
  canPerform,
}) {
  if (authorizations) {
    return (environments ?? []).filter((environment) =>
      canPerform({
        environmentId: environment.id,
        authorizations,
      }),
    );
  }
  return environments ?? [];
}

const EnvironmentSelectWithAutorizations = forwardRef(
  /**
   * @typedef Props
   * @property {string} name
   * @property {string} value
   * @property {string} [placeholder]
   * @property {object} [label]
   * @property {string | Array<string> | null} [authorizations]
   * @property {boolean} [includeWildcard]
   * @property {boolean} [isMultiple]
   */

  /**
   * @param {Props} props
   * @returns {import("react").FunctionComponentElement<Props> | null}
   */
  function EnvironmentSelectWithAutorizations(props, ref) {
    const { authorizations, includeWildcard, ...otherProps } = props;

    const {
      configuration: { environments },
    } = useConfiguration();

    const canPerform = useCanPerform();
    const { environmentListRelyOnAuthorizations, hasWidlcard } = useGuards();

    const localEnvironments = authorizations
      ? getListEnvironmentRelayOnAuthorizations({
          environments,
          authorizations,
          canPerform,
        })
      : environmentListRelyOnAuthorizations;

    if (localEnvironments?.length === 0) {
      return null;
    }

    return (
      <EnvironmentSelect
        environments={localEnvironments}
        forwardedRef={ref}
        includeWildcard={includeWildcard && hasWidlcard}
        {...otherProps}
      />
    );
  },
);

export default EnvironmentSelectWithAutorizations;
