// deps
import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";

// components
import Image from "../../components/Image";

// utils
import generateAdminPath from "../../libraries/utils/generateAdminPath";

const dimensions = [640, 360];

export default function SplashImage(props) {
  const { image, ...otherProps } = props;

  const intl = useIntl();

  return (
    <Image
      {...otherProps}
      dimensions={dimensions}
      src={generateAdminPath({
        id: "internal-assets",
        parameters: {
          filePath: `splashes/${image}.png`,
        },
        includeBasePath: true,
      })}
      alt={intl.formatMessage(
        {
          id: "raiden.admin.components.Splash.Image.texts.alt",
          defaultMessage:
            "Illustration {image, select, delete {de suppression} error {d’erreur} documents {de documents} home {d’accueil} done {de finition} confirm {de confirmation} choice {de choix} select {de sélection} empty {de vide} created {de création} publish {de publication} save {de sauvegarde} reject {de rejet} default {par défaut} add {d’ajout} other {{image}}}",
        },
        { image },
      )}
    />
  );
}

SplashImage.propTypes = {
  image: PropTypes.oneOf([
    "add",
    "default",
    "delete",
    "error",
    "documents",
    "home",
    "done",
    "confirm",
    "choice",
    "select",
    "empty",
    "created",
    "publish",
    "save",
    "reject",
  ]),
};
