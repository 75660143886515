// deps
import cookie from "cookie";

// libraries
import getBasePath from "@splitfire-agency/raiden-library/dist/libraries/utils/getBasePath";

/**
 * Hook utilisé pour enregistrer la colorMode dans le localStorage mais dans la clé previsou-chakra-ui-color-mode
 * useColorMode de chakra fait la même chose mais dans la clé chakra-ui-color-mode
 * On utilisera ce hook pour les pages qui incluent des composants provenant du legacy de la console
 *
 */
export default function usePreviousColorMode() {
  /**
   * Récupere la colorMode en cookie
   * @returns {string}
   */
  const previousColorMode = () => {
    const { "previous-chakra-ui-color-mode": previousColorMode } = cookie.parse(
      document.cookie,
    );
    return previousColorMode;
  };

  /**
   * Set the color mode value in the cookie
   * @param {string} colorMode
   */
  const setPreviousColorMode = (colorMode) => {
    document.cookie = cookie.serialize(
      "previous-chakra-ui-color-mode",
      colorMode,
      {
        path: getBasePath(process.env.NEXT_PUBLIC_ADMIN_BASE_PATH ?? ""),
        secure: false,
      },
    );
  };

  return {
    previousColorMode,
    setPreviousColorMode,
  };
}
