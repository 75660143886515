// deps
import { PHASE_PRODUCTION_BUILD } from "next/constants";

// hooks
import { serverApiFetcher } from "../../../hooks/useApiFetcher";

// constants
import { DEFAULT_CONFIGURATION } from "../../../constants/api";

/**
 * @param {object} params
 * @param {import("@splitfire-agency/raiden-library/dist/libraries/Uri").default} params.baseUri
 * @param {string} [params.locale]
 * @param {object} params.cookies
 * @param {import("http").IncomingMessage} [params.req] Requête initiale de la page
 * @param {string} params.configUrl
 */
export default async function nextGetConfiguration({
  baseUri,
  locale,
  cookies,
  req,
  configUrl,
}) {
  let error;

  if (process.env.NEXT_PHASE === PHASE_PRODUCTION_BUILD) {
    return {
      data: {},
      configuration: undefined,
    };
  }

  const configurationData = await serverApiFetcher(configUrl, undefined, {
    origin: baseUri.toString(),
    locale,
    cookies,
    req,
  }).catch(function (tempError) {
    error = tempError;
  });

  return {
    configuration: configurationData?.data ?? DEFAULT_CONFIGURATION,
    data: configurationData ?? { data: DEFAULT_CONFIGURATION },
    error,
  };
}
