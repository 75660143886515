// deps
import PropTypes from "prop-types";
import { chakra, useStyleConfig } from "@chakra-ui/react";
import NextImage from "next/image";

const ImageWithChakra = chakra(
  ({ dimensions = [0, 0], layout = "fill", ...otherProps }) => {
    /**
     * ? As explained earlier, NextJs typing is preventing auto-suggest for layout, width and height
     * ? Here we actually convert our component typing to NextJs typing
     */
    const [width, height] = dimensions;

    const layoutAndSize =
      height > 0 || width > 0
        ? {
            height,
            layout: layout === "fill" ? "intrinsic" : layout,
            width,
          }
        : {
            layout: "fill",
          };

    return <NextImage {...layoutAndSize} {...otherProps} />;
  },
);

/**
 * @typedef {import("@chakra-ui/react").ImageProps & import("next/image").ImageProps & {
 * variant?: any,
 * }} Props
 */
/**
 * @param {import("react").PropsWithChildren<Props>} props
 */
export default function Image(props) {
  const { variant } = props;

  const styles = useStyleConfig("Image", { variant });
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <ImageWithChakra sx={styles} {...props} />;
}

Image.propTypes = {
  variant: PropTypes.any,
};
