// deps
import PropTypes from "prop-types";
import { Box } from "@chakra-ui/react";

export default function PagePanelWrapper(props) {
  const { as: Tag, children, ...otherProps } = props;

  return (
    <Tag {...otherProps} flexGrow="1">
      {children}
    </Tag>
  );
}

PagePanelWrapper.propTypes = {
  as: PropTypes.any,
  children: PropTypes.node,
};

PagePanelWrapper.defaultProps = {
  as: Box,
};
