// constants
import {
  GOOGLE_TRACKING_STATE_VALUE_ACCEPTED,
  GOOGLE_TRACKING_STATE_VALUE_PENDING,
  GOOGLE_TRACKING_STATE_VALUE_REFUSED,
} from "../../../constants/googleTracking";

/**
 * Récupère l’état du tracking en fonction de la valeur du cookie.
 * @param {object} param0
 * @param {string} param0.cookieValue
 * @returns {keyof typeof import("../../../constants/googleTracking").GOOGLE_TRACKING_STATES}
 */
export default function googleGetTrackingState({ cookieValue }) {
  switch (cookieValue) {
    case "1":
      return GOOGLE_TRACKING_STATE_VALUE_ACCEPTED;
    case "0":
      return GOOGLE_TRACKING_STATE_VALUE_REFUSED;
    default:
      return GOOGLE_TRACKING_STATE_VALUE_PENDING;
  }
}
