import { Flex, Icon, IconButton, Tooltip } from "@chakra-ui/react";
import { useCallback } from "react";
import { MdDarkMode, MdLightMode } from "react-icons/md";
import { useIntl } from "react-intl";

/**
 * @typedef {object} Props
 * @property {import("@chakra-ui/system").ColorMode} colorMode
 * @property {(colorMode: import("@chakra-ui/system").ColorMode) => void} setColorMode
 */
/**
 * @param {Props} props
 */
function ThemeSwitch({ colorMode, setColorMode }) {
  // attention nous n'utilisons pas le hook useColorMode car celui ci doit être appelé dans un composant qui ne se trouve pas dans un composant qui force le thème

  const intl = useIntl();

  const setColor = useCallback(
    (mode) => {
      setColorMode(mode);
    },
    [setColorMode],
  );

  return (
    <Flex
      display="inline-flex"
      borderRadius="full"
      borderWidth="1px"
      borderColor="whiteAlpha.300">
      <Tooltip label={intl.formatMessage({ defaultMessage: "Thème clair" })}>
        <IconButton
          onClick={() => {
            setColor("light");
          }}
          icon={<Icon as={MdLightMode} />}
          variant="ghost"
          borderRadius="full"
          w="38px"
          h="38px"
          isActive={colorMode === "light"}
          aria-label={intl.formatMessage({ defaultMessage: "Thème clair" })}
        />
      </Tooltip>

      <Tooltip label={intl.formatMessage({ defaultMessage: "Thème sombre" })}>
        <IconButton
          onClick={() => {
            setColor("dark");
          }}
          icon={<Icon as={MdDarkMode} />}
          variant="ghost"
          borderRadius="full"
          w="38px"
          h="38px"
          isActive={colorMode === "dark"}
          aria-label={intl.formatMessage({ defaultMessage: "Thème sombre" })}
        />
      </Tooltip>
    </Flex>
  );
}

export default ThemeSwitch;
