// deps
import PropTypes from "prop-types";
import { Avatar, HStack, Text } from "@chakra-ui/react";

// libraries
import generatePampaUri from "@raiden/library-ui/libraries/utils/generatePampaUri";

// helpers
import { usersGetNicename } from "@raiden/library-ui/helpers/users";

/**
 * Calcul la taille pour pampa.
 * @param {object} param0
 * @param {object} param0.size
 */
function getSizes({ size }) {
  switch (size) {
    case "xs":
      return { width: 24, height: 24 };

    case "sm":
      return { width: 32, height: 32 };

    case "md":
      return { width: 48, height: 48 };

    case "lg":
      return { width: 64, height: 64 };

    default: {
      return { width: undefined, height: undefined };
    }
  }
}

/**
 * @typedef {object} Props
 * @property {import("@raiden/library-ui/types/UserAdmin").UserAdmin} user
 * @property {any} [size]
 * @property {boolean} [shouldRenderDisplayName]
 */
/**
 * @returns {import("react").FunctionComponentElement<Props>}
 */

/**
 * @param {Props} props
 */
export default function UsersAvatar(props) {
  const { user, size, shouldRenderDisplayName } = props;

  const { width, height } = getSizes({ size });

  const nicename = usersGetNicename({ resource: user });

  return (
    <HStack spacing="1rem">
      <Avatar
        size={size}
        src={
          user?.avatar
            ? generatePampaUri({
                image: user.avatar,
                format: "default",
                mode: "crop",
                width,
                height,
              })
            : undefined
        }
        name={nicename}
      />

      {shouldRenderDisplayName && <Text>{nicename}</Text>}
    </HStack>
  );
}

UsersAvatar.propTypes = {
  user: PropTypes.object,
  size: PropTypes.any,
  shouldRenderDisplayName: PropTypes.bool,
};

UsersAvatar.defaultProps = {
  shouldRenderDisplayName: true,
};
