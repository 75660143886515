// deps
import { createContext, memo, useContext, useMemo } from "react";
import PropTypes from "prop-types";

// hooks
import useCanPerform from "@raiden/library-ui/hooks/useCanPerform";
import { useConfiguration } from "@raiden/library-ui/hooks/useConfiguration";

/**
 * @typedef {object} GUARD
 * @property {string} [authorizations]
 */

/**
 * @typedef {object} GuardsContextValue
 * @property {GUARD} [gard]
 * @property {import("@raiden/library-ui/types/Configuration").ConfigurationEnvironment[]} environmentListRelyOnAuthorizations
 * @property {boolean} hasWidlcard
 */

/** @type {GuardsContextValue} */
const DEFAULT_VALUE = {
  gard: {},
  environmentListRelyOnAuthorizations: [],
  hasWidlcard: false,
};

export const GuardsContext = createContext(DEFAULT_VALUE);

const { Provider } = GuardsContext;

/**
 * @param {object} props
 * @param {import("@raiden/library-ui/types/Configuration").ConfigurationEnvironment[]} props.environments
 * @param {string} [props.authorizations]
 * @param {Function} props.canPerform
 */
function getListEnvironmentRelayOnAuthorizations({
  environments,
  authorizations,
  canPerform,
}) {
  if (authorizations) {
    const environmentFiltered = environments.filter((environment) =>
      canPerform({
        environmentId: environment.id,
        authorizations,
      }),
    );
    return {
      environments: environmentFiltered,
      hasWidlcard: canPerform({ environmentId: "*", authorizations }),
    };
  }
  return { environments, hasWidlcard: false };
}

/**
 * @typedef Props
 * @property {GUARD} guard
 * @property {any} children
 */

/**
 * @param {Props} props
 * @returns {import("react").FunctionComponentElement<Props>}
 */
function _GuardsProvider(props) {
  const { guard, children } = props;

  const canPerform = useCanPerform();

  const {
    configuration: { environments },
  } = useConfiguration();

  const { environments: localEnvironments, hasWidlcard } =
    getListEnvironmentRelayOnAuthorizations({
      environments: environments ?? [],
      authorizations: guard?.authorizations,
      canPerform,
    });

  const value = useMemo(
    function () {
      return {
        guard,
        environmentListRelyOnAuthorizations: localEnvironments,
        hasWidlcard,
      };
    },
    [guard, localEnvironments, hasWidlcard],
  );

  return <Provider value={value}>{children}</Provider>;
}

_GuardsProvider.displayName = "GuardsProvider";

_GuardsProvider.propTypes = {
  children: PropTypes.node,
};

export const GuardsProvider = memo(_GuardsProvider);

export const useGuards = function () {
  return useContext(GuardsContext);
};
