// deps
import { mode } from "@chakra-ui/theme-tools";

export default {
  baseStyle(props) {
    const { colorScheme } = props;
    return {
      field: {
        color: colorScheme
          ? mode(`${colorScheme}.600`, `${colorScheme}.300`)(props)
          : mode("gray.800", "whiteAlpha.900")(props),
      },
      icon: {
        color: colorScheme
          ? mode(`${colorScheme}.600`, `${colorScheme}.300`)(props)
          : mode("gray.800", "whiteAlpha.900")(props),
      },
    };
  },
};
