// deps
// eslint-disable-next-line import/no-unresolved
import Router from "@splitfire-agency/raiden-library/dist/libraries/RouterImplementation/Router";
// eslint-disable-next-line import/no-unresolved
import Uri from "@splitfire-agency/raiden-library/dist/libraries/Uri";

export const frontBaseUri = new Uri(
  // @ts-ignore
  process.env.NEXT_PUBLIC_FRONT_SCHEME,
  null,
  process.env.NEXT_PUBLIC_FRONT_HOST,
  null,
  null,
  null,
  null,
);

export const frontBaseUriWithBasePath = new Uri(
  // @ts-ignore
  process.env.NEXT_PUBLIC_FRONT_SCHEME,
  null,
  process.env.NEXT_PUBLIC_FRONT_HOST,
  null,
  process.env.NEXT_PUBLIC_FRONT_BASE_PATH,
  null,
  null,
);

/**
 * Build a router.
 * @param {import ("@splitfire-agency/raiden-library/dist/libraries/Uri").default} baseUri
 * @returns {import ("@splitfire-agency/raiden-library/dist/libraries/RouterImplementation/Router").default})}
 */
function buildRouter(baseUri) {
  const frontRouter = new Router();

  frontRouter.group(baseUri, (frontRouter) => {
    // assets (previewer)
    frontRouter
      .get("/public/<filePath>")
      .setId("@front.assets")
      .setConstraint("filePath", /.*/);

    // internal assets (nextjs)
    frontRouter
      .get("/<filePath>")
      .setId("@front.internal-assets")
      .setConstraint("filePath", /.*/);

    frontRouter.group("/<locale>", (frontRouter) => {
      frontRouter.get("/").setId("@front.home");
      frontRouter.get("/recherche").setId("@front.search");
      frontRouter.get("/actualites").setId("@front.news");
      frontRouter.get("/actualites/<postSlug>").setId("@front.articles.view");
      frontRouter.get("/pages/<postSlug>").setId("@front.page");

      frontRouter.group("/signin", (frontRouter) => {
        frontRouter.get("/").setId("@front.signin");
        frontRouter.get("/password-recovery").setId("@front.password-recovery");
      });

      frontRouter.group("/account", (frontRouter) => {
        frontRouter.get("/").setId("@front.account");

        frontRouter.group("/my-account", (frontRouter) => {
          frontRouter.get("/#test").setId("@front.account.my-account");
          frontRouter
            .get("/personal-informations")
            .setId("@front.account.my-account.personnal-informations");
        });

        frontRouter.group("/hotels", (frontRouter) => {
          frontRouter.get("/").setId("@front.account.hotels");
        });
      });
    });

    frontRouter.group("/api", (frontRouter) => {
      frontRouter
        .get("/translation-messages")
        .setId("@front.api.translation-messages");
    });
  });

  return frontRouter;
}

export const frontRouter = buildRouter(frontBaseUri);
export const frontRouterWithBasePath = buildRouter(frontBaseUriWithBasePath);
