// deps
// eslint-disable-next-line import/no-unresolved
import Router from "@splitfire-agency/raiden-library/dist/libraries/RouterImplementation/Router";
// eslint-disable-next-line import/no-unresolved
import Uri from "@splitfire-agency/raiden-library/dist/libraries/Uri";

const kioscRouter = new Router();

export const kioscBaseUri = new Uri(
  process.env.NEXT_PUBLIC_KIOSC_SCHEME ?? "",
  null,
  process.env.NEXT_PUBLIC_KIOSC_HOST,
  null,
  process.env.NEXT_PUBLIC_KIOSC_BASE_PATH,
  null,
  null,
);

kioscRouter.group(kioscBaseUri, function (kioscRouter) {
  kioscRouter.get("").setId("@kiosc");
  kioscRouter.get("/login").setId("@kiosc.login");
});

export default kioscRouter;
