// deps
import {
  Box,
  Button,
  DarkMode,
  Flex,
  Grid,
  GridItem,
  HStack,
  Icon,
  Menu,
  MenuButton,
  Portal,
  Text,
  useBreakpointValue,
  useColorMode,
} from "@chakra-ui/react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";

// components
import EnvironmentSelect from "../../../components/EnvironmentSelectWithoutAuthorizations";
import Logo from "../../../components/Logo";
import UsersAvatar from "../../../components/Users/Avatar";

// containers
import UserMenu from "../../UserMenu";

// hooks
import useAuth from "@raiden/library-ui/hooks/useAuth";
import usePreviousColorMode from "@raiden/library-ui/contexts/usePreviousColorMode";

// contexts
import { IoChevronDownOutline, IoMenuOutline } from "react-icons/io5";
import { useDefaultEnvironments } from "../../../contexts/DefaultEnvironments";
import { usePreferences } from "@raiden/library-ui/contexts/Preferences";
import ThemeSwitch from "../ThemeSwitch";

export default function SignedInLayoutHeader(props) {
  const { onClickMenu } = props;

  const { userAdmin } = useAuth();

  const { defaultEnvironments, setDefaultEnvironments } =
    useDefaultEnvironments();

  const intl = useIntl();

  const { colorMode, setColorMode } = useColorMode();
  const { setPreviousColorMode } = usePreviousColorMode();

  const responsive = useBreakpointValue({
    base: true,
    lg: false,
  });

  const { shouldRenderEnvironments } = usePreferences();

  /**
   * Gère le changement de thème.
   * @param {import("@chakra-ui/system").ColorMode} colorMode
   */
  function handleChangeTheme(colorMode) {
    setColorMode(colorMode);
    setPreviousColorMode(colorMode);
  }

  /**
   * Gère le changement d’environnement par défaut.
   */
  function handleChangeDefaultEnvironment(event) {
    setDefaultEnvironments(event.target.value);
  }

  return (
    <DarkMode>
      <Flex
        sx={{
          "@media print": {
            display: "none",
          },
        }}
        pos="fixed"
        zIndex="12"
        left="0"
        right="0"
        top="0"
        h="4rem"
        borderBottomColor="gray.600"
        borderBottomWidth="0.0625rem"
        borderBottomStyle="solid"
        alignItems="center"
        px="0.625rem"
        py="0.75rem"
        bgColor="gray.700"
        color="white">
        {responsive ? (
          <Grid templateColumns="repeat(3, 1fr)" gap={"0.3125rem"} w="full">
            <GridItem>
              <HStack
                spacing="0.625rem"
                as="button"
                h="full"
                onClick={onClickMenu}>
                <Icon as={IoMenuOutline} w="1.5rem" h="1.5rem" />

                <Text>
                  {intl.formatMessage({
                    id: "raiden.admin.containers.SignedInLayout.texts.menu",
                    defaultMessage: "Menu",
                  })}
                </Text>
              </HStack>
            </GridItem>

            <GridItem>
              <Flex h="1.18rem" justifyContent="center">
                <Logo dimensions={[133, 19]} />
              </Flex>
            </GridItem>
          </Grid>
        ) : (
          <>
            <Box flexGrow={1}>
              <Box h="3rem">
                <Logo dimensions={[270, 40]} />
              </Box>
            </Box>

            <HStack spacing="1rem">
              <ThemeSwitch
                colorMode={colorMode}
                setColorMode={handleChangeTheme}
              />

              {shouldRenderEnvironments && (
                <EnvironmentSelect
                  width="15.625rem"
                  isMultiple={true}
                  value={defaultEnvironments}
                  onChange={handleChangeDefaultEnvironment}
                />
              )}

              {userAdmin && (
                <Menu placement="bottom">
                  <MenuButton
                    as={Button}
                    rightIcon={<Icon as={IoChevronDownOutline} />}
                    variant="ghost"
                    fontWeight="normal">
                    <UsersAvatar size="md" user={userAdmin} />
                  </MenuButton>

                  <Portal>
                    <UserMenu />
                  </Portal>
                </Menu>
              )}
            </HStack>
          </>
        )}
      </Flex>
    </DarkMode>
  );
}

SignedInLayoutHeader.propTypes = {
  onClickMenu: PropTypes.func,
};
