import { useRouter } from "next/router";
import { useMemo } from "react";

/**
 * @param {string | string[] | undefined} value
 * @returns {string | undefined}
 */
export function getRouterString(value) {
  return typeof value === "string" ? value : undefined;
}

/**
 * @param {string} key
 * @returns {string | undefined}
 */
export function useRouterString(key) {
  const router = useRouter();

  const value = useMemo(() => {
    return getRouterString(router.query[key]);
  }, [key, router.query]);

  return value;
}
