// package
import pkg from "../../../package.json";

/**
 * Retourne le titre à afficher.
 * @param {string} title
 * @returns {string}
 */
export default function getTitle(title) {
  return `${title} | ${pkg.displayName}`;
}
