// deps
// eslint-disable-next-line import/no-unresolved
import Router from "@splitfire-agency/raiden-library/dist/libraries/RouterImplementation/Router";
// eslint-disable-next-line import/no-unresolved
import Uri from "@splitfire-agency/raiden-library/dist/libraries/Uri";

const apiRouter = new Router();

apiRouter.group(
  new Uri(
    // @ts-ignore
    process.env.NEXT_PUBLIC_API_SCHEME,
    null,
    process.env.NEXT_PUBLIC_API_HOST,
    null,
    process.env.NEXT_PUBLIC_API_BASE_PATH,
    null,
    null,
  ),
  function (apiRouter) {
    apiRouter.group("/api", function (apiRouter) {
      // Configuration
      apiRouter.get("/configuration").setId("@configuration");

      // Environments
      apiRouter.group("/environments", function (apiRouter) {
        apiRouter.get("").setId("@environments.search");
        apiRouter.post("").setId("@environments.create");
        apiRouter.group("/action", function (apiRouter) {
          apiRouter.post("/merge").setId("@environments.merge");
        });

        apiRouter.group("/<environmentId>", function (apiRouter) {
          apiRouter.setConstraint("environmentId", /\d+/);
          apiRouter.get("/quotas").setId("@environments.quotas");
          apiRouter.get("").setId("@environments.view");
          apiRouter.get("").setId("@environments.quotas");
          apiRouter.post("").setId("@environments.update");
          apiRouter.post("").setId("@environments.updateRecordsAccounts");
          apiRouter.post("").setId("@environments.updateDepartments");
          apiRouter.post("").setId("@environments.softDelete");
          apiRouter.post("").setId("@environments.delete");

          apiRouter.group("/action", function (apiRouter) {
            apiRouter.post("/restore").setId("@environments.restore");
            apiRouter
              .post("/attach-department")
              .setId("@environments.attachDepartment");
          });
        });
      });

      // Amenities
      apiRouter.group("/amenities", function (apiRouter) {
        apiRouter.get("").setId("@amenities.search");
        apiRouter.post("").setId("@amenities.create");

        apiRouter.group("/<id>", function (apiRouter) {
          apiRouter.setConstraint("id", /\d+/);
          apiRouter.get("").setId("@amenities.view");
          apiRouter.post("").setId("@amenities.update");
          apiRouter.post("").setId("@amenities.softDelete");
          apiRouter.post("").setId("@amenities.delete");

          apiRouter.group("/action", function (apiRouter) {
            apiRouter.post("/autocomplete").setId("@amenities.autocomplete");
            apiRouter.post("/restore").setId("@amenities.restore");
            apiRouter.get("/dictionary").setId("@amenities.dictionary");
          });
        });
      });

      // Compositions
      apiRouter.group("/compositions", function (apiRouter) {
        apiRouter.get("").setId("@compositions.search");
        apiRouter.post("").setId("@compositions.create");

        apiRouter.group("/<id>", function (apiRouter) {
          apiRouter.setConstraint("id", /\d+/);
          apiRouter.get("").setId("@compositions.view");
          apiRouter.post("").setId("@compositions.update");
          apiRouter.post("").setId("@compositions.softDelete");
          apiRouter.post("").setId("@compositions.delete");

          apiRouter.group("/action", function (apiRouter) {
            apiRouter.post("/autocomplete").setId("@compositions.autocomplete");
            apiRouter.post("/restore").setId("@compositions.restore");
          });
        });
      });

      // Departements
      apiRouter.group("/departments", function (apiRouter) {
        apiRouter.get("").setId("@departments.viewAny");
        apiRouter.group("/action", function (apiRouter) {
          apiRouter.get("/autocomplete").setId("@departments.autocomplete");
        });
      });

      // Régions
      apiRouter.group("/regions", function (apiRouter) {
        apiRouter.get("").setId("@regions.viewAny");
        apiRouter.group("/action", function (apiRouter) {
          apiRouter.get("/autocomplete").setId("@regions.autocomplete");
        });
      });

      // Redirections urls
      apiRouter.group("/redirections/urls", function (apiRouter) {
        apiRouter.get("").setId("@api.redirections.urls.viewAny");
        apiRouter.post("").setId("@api.redirections.urls.create");

        apiRouter.group("/action", function (apiRouter) {
          apiRouter.post("/hit").setId("@api.redirections.urls.hit");
        });

        apiRouter.group("/<redirectionUrlId>", function (apiRouter) {
          apiRouter.setConstraint("redirectionUrlId", /\d+/);
          apiRouter.get("").setId("@api.redirections.urls.view");
          apiRouter.post("").setId("@api.redirections.urls.update");
          apiRouter.post("").setId("@api.redirections.urls.delete");

          apiRouter.group("/action", function (apiRouter) {
            apiRouter.post("/restore").setId("@api.redirections.urls.restore");
          });
        });
      });

      // Cities
      apiRouter.group("/cities", function (apiRouter) {
        apiRouter.get("").setId("@cities.viewAny");
        apiRouter.post("").setId("@cities.create");
        apiRouter.group("/<cityId>", function (apiRouter) {
          apiRouter.setConstraint("cityId", /\d+/);
          apiRouter.get("").setId("@cities.view");
          apiRouter.post("").setId("@cities.update");
          apiRouter.post("").setId("@cities.softDelete");
          apiRouter.post("").setId("@cities.delete");
          apiRouter.group("/action", function (apiRouter) {
            apiRouter.post("/restore").setId("@cities.restore");
          });
        });
      });

      // Users
      apiRouter.group("/users", function (apiRouter) {
        // Users/Groups
        apiRouter.group("/groups", function (apiRouter) {
          apiRouter.get("").setId("@usersGroups.search");

          apiRouter.post("").setId("@usersGroups.create");
          apiRouter.group("/action", function (apiRouter) {
            apiRouter.get("/autocomplete").setId("@usersGroups.autocomplete");
          });

          apiRouter.group("/<usersGroupId>", function (apiRouter) {
            apiRouter.setConstraint("usersGroupId", /\d+/);

            apiRouter.get("").setId("@usersGroups.show");

            apiRouter.post("").setId("@usersGroups.edit");

            apiRouter.delete("").setId("@usersGroups.delete");
          });
        });

        // Users/Admins
        apiRouter.group("/admins", function (apiRouter) {
          apiRouter.get("").setId("@usersAdmins.search");

          apiRouter.post("").setId("@usersAdmins.create");

          apiRouter.group("/action", function (apiRouter) {
            apiRouter.group("/autocomplete", function (apiRouter) {
              apiRouter.get("").setId("@usersAdmins.autocomplete");
            });
          });

          apiRouter.group("/<usersAdminId>", function (apiRouter) {
            apiRouter.setConstraint("usersAdminId", /\d+/);

            apiRouter.get("").setId("@usersAdmins.show");

            apiRouter.post("").setId("@usersAdmins.edit");

            apiRouter.delete("").setId("@usersAdmins.delete");
          });
        });

        // Users/PasswordResets
        apiRouter.group("/password-resets", function (apiRouter) {
          apiRouter.post("").setId("@usersPasswordResets.create");

          apiRouter.group("/action", function (apiRouter) {
            apiRouter
              .post("/finish")
              .setId("@usersPasswordResets.actionFinish");
          });
        });

        // Users/Customers
        apiRouter.group("/customers", (apiRouter) => {
          apiRouter.get("").setId("@customers.viewAny");
          apiRouter.group("/<customerId>", function (apiRouter) {
            apiRouter.setConstraint("customerId", /\d+/);

            apiRouter.get("").setId("@customers.view");
            apiRouter.post("").setId("@customers.update");
          });
          apiRouter.group("/action", function (apiRouter) {
            apiRouter.group("/autocomplete", function (apiRouter) {
              apiRouter.get("").setId("@customers.autocomplete");
            });
          });
        });

        //users/debit-mandates
        apiRouter.group("/debit-mandates", (apiRouter) => {
          apiRouter.get("").setId("@debitMandates.viewAny");
          apiRouter.post("").setId("@debitMandates.create");
          apiRouter.group("/<debitMandateId>", function (apiRouter) {
            apiRouter.setConstraint("debitMandateId", /\d+/);

            apiRouter.group("/action", function (apiRouter) {
              apiRouter.group("/cancel", function (apiRouter) {
                apiRouter.get("").setId("@debitMandates.cancel");
              });
            });
          });
        });
      });

      // signatures
      apiRouter.group("/signatures", function (apiRouter) {
        apiRouter.group("/<signatureId>", function (apiRouter) {
          apiRouter.setConstraint("signatureId", /\d+/);

          apiRouter.group("/action", function (apiRouter) {
            apiRouter.group("/download", function (apiRouter) {
              apiRouter.get("").setId("@signatures.download");
            });
            apiRouter.group("/download-audit-trail", function (apiRouter) {
              apiRouter.get("").setId("@signatures.downloadAuditTrail");
            });
          });
        });
      });

      // Posts
      apiRouter.group("/posts", function (apiRouter) {
        apiRouter.get("").setId("@posts.viewAny");

        apiRouter.post("").setId("@posts.create");

        apiRouter.group("/<postId>", function (apiRouter) {
          apiRouter.setConstraint("postId", /\d+/);

          apiRouter.get("").setId("@posts.show");

          apiRouter.post("").setId("@posts.edit");

          apiRouter.delete("").setId("@posts.delete");

          apiRouter.group("/action", function (apiRouter) {
            apiRouter.post("/restore").setId("@posts.restore");
          });
        });

        // Posts/Categories
        apiRouter.group("/categories", function (apiRouter) {
          apiRouter.get("").setId("@postsCategories.viewAny");

          apiRouter.get("/tree").setId("@postsCategories.treeView");

          apiRouter.post("/tree").setId("@postsCategories.treeUpdate");

          apiRouter.post("").setId("@postsCategories.create");

          apiRouter.group("/<postsCategoryId>", function (apiRouter) {
            apiRouter.setConstraint("postsCategoryId", /\d+/);

            apiRouter.get("").setId("@postsCategories.show");

            apiRouter.post("").setId("@postsCategories.update");

            apiRouter.post("").setId("@postsCategories.delete");

            apiRouter.group("/action", function (apiRouter) {
              apiRouter.group("/restore", function (apiRouter) {
                apiRouter.post("").setId("@postsCategories.restore");
              });
            });
          });
        });

        apiRouter.group("/revisions", function (apiRouter) {
          apiRouter.group("/<postsRevisionId>", function (apiRouter) {
            apiRouter.setConstraint("postsRevisionId", /\d+/);

            apiRouter.post("").setId("@postsRevisions.update");

            apiRouter.delete("").setId("@postsRevisions.delete");

            apiRouter.group("/action", function (apiRouter) {
              apiRouter.post("/review").setId("@postsRevisions.review");

              apiRouter.post("/restore").setId("@postsRevisions.restore");
            });
          });
        });
      });

      // Covers
      apiRouter.group("/covers", function (apiRouter) {
        apiRouter.get("").setId("@covers.viewAny");

        apiRouter.post("").setId("@covers.create");

        apiRouter.group("/<coverId>", function (apiRouter) {
          apiRouter.setConstraint("coverId", /\d+/);

          apiRouter.post("").setId("@covers.edit");

          apiRouter.delete("").setId("@covers.delete");
        });
      });

      // Files
      apiRouter.group("/files", function (apiRouter) {
        apiRouter.get("").setId("@files.viewAny");

        apiRouter.post("").setId("@files.create");

        apiRouter.group("/<fileId>", function (apiRouter) {
          apiRouter.setConstraint("fileId", /\d+/);

          apiRouter.delete("").setId("@files.delete");

          apiRouter.get("").setId("@files.view");

          apiRouter.post("").setId("@files.update");

          apiRouter.group("/action", function (apiRouter) {
            apiRouter.post("/restore").setId("@files.restore");
          });
        });
      });

      // Countries
      apiRouter.group("/countries", function (apiRouter) {
        apiRouter.get("").setId("@countries.search");
        apiRouter.post("").setId("@countries.create");

        apiRouter.group("/<countryId>", function (apiRouter) {
          apiRouter.setConstraint("countryId", /\d+/);
          apiRouter.get("").setId("@countries.show");
          apiRouter.post("").setId("@countries.edit");
          apiRouter.post("").setId("@countries.delete");

          apiRouter.group("/action", function (apiRouter) {
            apiRouter.post("/restore").setId("@countries.restore");
          });
        });
      });

      // Menus
      apiRouter.group("/menus", function (apiRouter) {
        apiRouter.get("").setId("@menus.viewAny");
        apiRouter.post("").setId("@menus.create");

        apiRouter.group("/<menuId>", function (apiRouter) {
          apiRouter.setConstraint("menuId", /\d+/);
          apiRouter.get("").setId("@menus.view");
          apiRouter.post("").setId("@menus.update");
          apiRouter.delete("").setId("@menus.delete");

          apiRouter.group("/action", function (apiRouter) {
            apiRouter.post("/restore").setId("@menus.restore");
          });
        });
      });

      // Authorizations
      apiRouter.group("/authorizations", function (apiRouter) {
        apiRouter.get("").setId("@authorizations.viewAny");
      });

      // Carts
      apiRouter.group("/carts", function (apiRouter) {
        apiRouter.group("/<cartId>", function (apiRouter) {
          apiRouter.setConstraint("cartId", /\d+/);

          apiRouter.get("").setId("@cartsBookings.show");
        });

        apiRouter.group("/<cartId>", function (apiRouter) {
          apiRouter.setConstraint("cartId", /\d+/);
          apiRouter.group("/bookings", function (apiRouter) {
            apiRouter.group("/<bookingId>", function (apiRouter) {
              apiRouter.setConstraint("bookingId", /\d+/);

              apiRouter.group("/action", function (apiRouter) {
                apiRouter.post("/cancel").setId("@cartsBookings.cancel");
              });

              // Carts bookings messages
              apiRouter.group("/messages", function (apiRouter) {
                apiRouter.get("").setId("@cartsBookingsMessages.viewAny");
                apiRouter.post("").setId("@cartsBookingsMessages.create");
              });

              // Carts bookings issues
              apiRouter.group("/issues", function (apiRouter) {
                apiRouter.post("").setId("@cartsBookingsIssues.create");

                apiRouter.group("/<issueId>", function (apiRouter) {
                  apiRouter.setConstraint("issueId", /\d+/);
                  apiRouter.get("").setId("@cartsBookingsIssues.show");
                  apiRouter.post("").setId("@cartsBookingsIssues.update");
                });
              });
            });
          });
        });

        apiRouter.group("/bookings", function (apiRouter) {
          apiRouter.get("").setId("@cartsBookings.viewAny");

          apiRouter.group("/<bookingId>", function (apiRouter) {
            apiRouter.setConstraint("bookingId", /\d+/);
            apiRouter.group("/issues", function (apiRouter) {
              apiRouter.post("").setId("@cartsBookingsIssues.create");
            });
          });

          apiRouter.group("/action", function (apiRouter) {
            apiRouter.group("/export", function (apiRouter) {
              apiRouter.get("").setId("@cartsBookings.export");
            });
          });

          // Carts bookings issues for search
          apiRouter.group("/issues", function (apiRouter) {
            apiRouter.get("").setId("@cartsBookingsIssues.viewAny");
          });
        });
      });

      // accounts records
      apiRouter.group("/accounts", function (apiRouter) {
        apiRouter.group("/records", function (apiRouter) {
          apiRouter.group("/exports", function (apiRouter) {
            apiRouter.get("").setId("@accountsRecordsExports.viewAny");

            apiRouter.group("/<exportId>", function (apiRouter) {
              apiRouter.setConstraint("exportId", /\d+/);
              apiRouter.group("/action", function (apiRouter) {
                apiRouter
                  .post("/download")
                  .setId("@accountsRecordsExports.download");
              });
            });
          });
        });
      });

      // Hotels
      apiRouter.group("/hotels", function (apiRouter) {
        apiRouter.get("").setId("@hotels.viewAny");

        apiRouter.group("/<hotelId>", function (apiRouter) {
          apiRouter.setConstraint("hotelId", /\d+/);
          apiRouter.get("").setId("@hotels.view");
          apiRouter.post("").setId("@hotels.update");

          apiRouter.group("/action", function (apiRouter) {
            apiRouter.get("/export").setId("@hotels.export");
            apiRouter.group("/autocomplete", function (apiRouter) {
              apiRouter.get("").setId("@hotels.autocomplete");
            });
          });
        });

        apiRouter.group("/rooms", (apiRouter) => {
          apiRouter.group("/<roomId>", function (apiRouter) {
            apiRouter.setConstraint("hotelId", /\d+/);
            apiRouter.setConstraint("roomId", /\d+/);
            apiRouter.post("").setId("@rooms.update");
            apiRouter.get("").setId("@rooms.view");
            apiRouter.group("/availabilities", function (apiRouter) {
              // Export iCal
              apiRouter
                .post("/calendar")
                .setId("@rooms.availabilitiesCalendar");

              apiRouter.group("/remotes", function (apiRouter) {
                // iCal
                apiRouter
                  .post("")
                  .setId("@hotelsRoomsAvailabilitiesRemotes.create");

                apiRouter.group("/<remoteId>", function (apiRouter) {
                  apiRouter.setConstraint("remoteId", /\d+/);
                  apiRouter
                    .delete("")
                    .setId("@hotelsRoomsAvailabilitiesRemotes.delete");

                  apiRouter
                    .post("")
                    .setId("@hotelsRoomsAvailabilitiesRemotes.update");

                  apiRouter
                    .get("")
                    .setId("@hotelsRoomsAvailabilitiesRemotes.show");
                });
              });
            });
          });
          apiRouter.get("").setId("@hotelsRooms.viewAny");
          apiRouter.group("/action", function (apiRouter) {
            apiRouter
              .get("/export-remote-url")
              .setId("@hotelsRooms.exportRemoteUrl");
            apiRouter.get("/export").setId("@hotelsRooms.export");

            apiRouter.group("/autocomplete", function (apiRouter) {
              apiRouter.get("").setId("@hotelsRooms.autocomplete");
            });
          });
          apiRouter.group("/availabilities", function (apiRouter) {
            apiRouter.group("/remotes", function (apiRouter) {
              // iCal
              apiRouter
                .get("")
                .setId("@hotelsRoomsAvailabilitiesRemotes.viewAny");

              apiRouter.group("/action", function (apiRouter) {
                apiRouter
                  .get("/export")
                  .setId("@hotelsRoomsAvailabilitiesRemotes.export");
              });
            });
          });

          apiRouter.group("/services", function (apiRouter) {
            apiRouter.get("/types").setId("@roomsServices.types");
            apiRouter.get("").setId("@roomsServices.viewAny");
            apiRouter.post("").setId("@roomsServices.create");

            apiRouter.group("/<roomServiceId>", function (apiRouter) {
              apiRouter.setConstraint("roomServiceId", /\d+/);
              apiRouter.delete("").setId("@roomsServices.delete");
              apiRouter.post("").setId("@roomsServices.update");
              apiRouter.get("").setId("@roomsServices.show");

              apiRouter.group("/subscribers", function (apiRouter) {
                apiRouter.post("").setId("@roomsServices.subscribe");
              });
            });
          });

          apiRouter.group("/discounts", function (apiRouter) {
            apiRouter.get("").setId("@roomsDiscounts.viewAny");
            apiRouter.post("").setId("@roomsDiscounts.create");

            apiRouter.group("/<discountId>", function (apiRouter) {
              apiRouter.setConstraint("discountId", /\d+/);
              apiRouter.delete("").setId("@roomsDiscounts.delete");
              apiRouter.post("").setId("@roomsDiscounts.update");
              apiRouter.get("").setId("@roomsDiscounts.show");

              apiRouter.group("/subscribers", function (apiRouter) {
                apiRouter.post("").setId("@roomsDiscounts.subscribe");
              });
            });
          });

          // Documents (Pièce jointe)
          apiRouter.group("/documents", function (apiRouter) {
            apiRouter.get("").setId("@roomsDocuments.viewAny");
            apiRouter.post("").setId("@roomsDocuments.create");

            apiRouter.group("/<documentId>", function (apiRouter) {
              apiRouter.setConstraint("documentId", /\d+/);
              apiRouter.delete("").setId("@roomsDocuments.delete");
              apiRouter.post("").setId("@roomsDocuments.update");
              apiRouter.get("").setId("@roomsDocuments.show");

              apiRouter.group("/subscribers", function (apiRouter) {
                apiRouter.post("").setId("@roomsDocuments.subscribe");
              });

              apiRouter.group("/action", (apiRouter) => {
                apiRouter.post("/restore").setId("@roomsDocuments.restore");
              });
            });
          });

          // Seasons (Tarifs)
          apiRouter.group("/seasons", function (apiRouter) {
            apiRouter.get("").setId("@roomsSeasons.viewAny");
            apiRouter.post("").setId("@roomsSeasons.create");
            apiRouter.post("/duplicate").setId("@roomsSeasons.duplicate");
            apiRouter.post("/position").setId("@roomsSeasons.position");

            apiRouter.group("/<seasonId>", function (apiRouter) {
              apiRouter.setConstraint("seasonId", /\d+/);
              apiRouter.delete("").setId("@roomsSeasons.delete");
              apiRouter.post("").setId("@roomsSeasons.update");
              apiRouter.get("").setId("@roomsSeasons.show");
            });
          });
        });
      });

      // Tourist tax
      apiRouter.group("/tourist-tax", function (apiRouter) {
        // deliberations
        apiRouter.group("/deliberations", function (apiRouter) {
          apiRouter.get("").setId("@touristTaxDeliberations.viewAny");
          apiRouter.group("/<deliberationId>", function (apiRouter) {
            apiRouter.setConstraint("deliberationId", /\d+/);
            apiRouter.get("").setId("@touristTaxDeliberations.view");
            apiRouter.post("").setId("@touristTaxDeliberations.update");
          });
        });
      });

      // Notifications
      apiRouter.group("/notifications", function (apiRouter) {
        apiRouter.get("").setId("@notifications.viewAny");
      });

      // years
      apiRouter.group("/years", (apiRouter) => {
        apiRouter.get("").setId("@years.viewAny");
      });

      // Payments
      apiRouter.group("/payments", function (apiRouter) {
        apiRouter.get("").setId("@payments.viewAny");
        apiRouter.group("/action", (apiRouter) => {
          apiRouter.get("/export").setId("@payments.export");
        });
        apiRouter.group("/<paymentId>", (apiRouter) => {
          apiRouter.setConstraint("paymentId", /\d+/);
          apiRouter.group("/action", (apiRouter) => {
            apiRouter.post("/sold").setId("@payments.sold");
            apiRouter.post("/cancel").setId("@payments.cancel");
          });
        });

        // remittances
        apiRouter.group("/remittances", function (apiRouter) {
          apiRouter.get("").setId("@paymentsRemittances.viewAny");
          apiRouter.post("").setId("@paymentsRemittances.create");
          apiRouter.group("/<remittanceId>", (apiRouter) => {
            apiRouter.setConstraint("remittanceId", /\d+/);
            apiRouter.get("").setId("@paymentsRemittances.view");
            apiRouter.post("").setId("@paymentsRemittances.update");
          });
        });

        // sepas
        apiRouter.group("/sepas", (apiRouter) => {
          apiRouter.get("").setId("@paymentsSepas.viewAny");
          apiRouter.group("/action", (apiRouter) => {
            apiRouter.get("/export").setId("@paymentsSepas.export");
          });
          apiRouter.group("/<sepaId>", (apiRouter) => {
            apiRouter.setConstraint("sepaId", /\d+/);
            apiRouter.post("").setId("@paymentsSepas.view");

            apiRouter.group("/transactions", (apiRouter) => {
              apiRouter.get("").setId("@paymentsSepasTransactions.viewAny");
              apiRouter.group("/<transactionId>", (apiRouter) => {
                apiRouter.setConstraint("transactionId", /\d+/);
                apiRouter.group("/action/export", (apiRouter) => {
                  apiRouter.get("").setId("@paymentsSepasTransactions.export");
                });
              });
            });

            apiRouter.group("/action", (apiRouter) => {
              apiRouter.get("/finish").setId("@paymentsSepas.finish");
              apiRouter.group("/download", (apiRouter) => {
                apiRouter.group("/<filename>", (apiRouter) => {
                  apiRouter.setConstraint("filename", /\d+/);
                  apiRouter.get("").setId("@paymentsSepas.download");
                });
              });
              apiRouter.group("/download-export", (apiRouter) => {
                apiRouter.group("/<filename>", (apiRouter) => {
                  apiRouter.setConstraint("filename", /\d+/);
                  apiRouter.get("").setId("@paymentsSepas.downloadExport");
                });
              });
            });
          });
        });
      });

      // partners
      apiRouter.group("/partners", (apiRouter) => {
        apiRouter.get("").setId("@partners.viewAny");
        apiRouter.group("/<partnerId>", function (apiRouter) {
          apiRouter.setConstraint("partnerId", /\d+/);
          apiRouter.post("").setId("@partners.update");
          apiRouter.group("/action", (apiRouter) => {
            apiRouter.post("/attach").setId("@partners.attach");
            apiRouter.post("/detach").setId("@partners.detach");
          });
        });
      });

      // subscriptions
      apiRouter.group("/subscriptions", (apiRouter) => {
        apiRouter.get("").setId("@subscriptions.viewAny");
        apiRouter.post("").setId("@subscriptions.create");
        apiRouter.group("/<subscriptionId>", function (apiRouter) {
          apiRouter.setConstraint("subscriptionId", /\d+/);
          apiRouter.get("").setId("@subscriptions.view");
          apiRouter.post("").setId("@subscriptions.update");
          apiRouter.group("/customers", function (apiRouter) {
            apiRouter.get("").setId("@subscriptionsCustomers.create");
          });
        });
        apiRouter.group("/customers", function (apiRouter) {
          apiRouter.get("").setId("@subscriptionsCustomers.viewAny");
          apiRouter.group("/<subscriptionCustomerId>", function (apiRouter) {
            apiRouter.setConstraint("subscriptionCustomerId", /\d+/);
            apiRouter.get("").setId("@subscriptionsCustomers.view");
            apiRouter.group("/action", function (apiRouter) {
              apiRouter
                .get("/batch")
                .setId("@subscriptionsCustomers.batchRooms");
              apiRouter.get("/cancel").setId("@subscriptionsCustomers.cancel");
            });
          });
          apiRouter.group("/action", function (apiRouter) {
            apiRouter.get("/export").setId("@subscriptionsCustomers.export");
          });
        });
      });

      // metrics
      apiRouter.group("/metrics", (apiRouter) => {
        apiRouter.get("").setId("@metrics.viewAny");
      });

      // invoices
      apiRouter.group("/invoices", (apiRouter) => {
        apiRouter.get("").setId("@invoices.viewAny");
        apiRouter.group("/<invoiceId>", function (apiRouter) {
          apiRouter.setConstraint("invoiceId", /\d+/);
          apiRouter.group("/action", function (apiRouter) {
            apiRouter.get("/download").setId("@invoices.download");
            apiRouter.get("/export").setId("@invoices.export");
            apiRouter.get("/download-batch").setId("@invoices.downloadBatch");
          });
        });
      });

      // insurances
      apiRouter.group("/insurances", (apiRouter) => {
        apiRouter.group("/contracts", (apiRouter) => {
          apiRouter.get("").setId("@insurances.viewAny");
          apiRouter.group("/exports", (apiRouter) => {
            apiRouter.get("").setId("@insurances.export");
            apiRouter.group("/<exportId>", (apiRouter) => {
              apiRouter.setConstraint("exportId", /\d+/);
              apiRouter
                .get("/action/download")
                .setId("@insurances.exportDownload");
            });
          });
        });
      });

      // rentals
      apiRouter.group("/rentals", (apiRouter) => {
        apiRouter.group("/logs", (apiRouter) => {
          apiRouter.get("").setId("@rentalsLogs.viewAny");
          apiRouter.group("/<logId>", function (apiRouter) {
            apiRouter.setConstraint("logId", /\d+/);
            apiRouter.get("").setId("@rentalsLogs.show");
          });
        });
      });

      // slideshows
      apiRouter.group("/slideshows", (apiRouter) => {
        apiRouter.get("").setId("@slideshows.viewAny");
        apiRouter.group("/<slideshowId>", function (apiRouter) {
          apiRouter.setConstraint("slideshowId", /\d+/);
          apiRouter.get("").setId("@slideshows.show");
          apiRouter.post("").setId("@slideshows.delete");
          apiRouter.post("").setId("@slideshows.delete");

          apiRouter.group("/action", function (apiRouter) {
            apiRouter.post("/restore").setId("@slideshows.restore");
          });

          apiRouter.group("/items", function (apiRouter) {
            apiRouter.get("").setId("@slideshowsItems.viewAny");
            apiRouter.post("").setId("@slideshowsItems.create");

            apiRouter.group("/<slideshowItemId>", function (apiRouter) {
              apiRouter.setConstraint("slideshowItemId", /\d+/);
              apiRouter.get("").setId("@slideshowsItems.show");
              apiRouter.post("").setId("@slideshowsItems.update");
              apiRouter.post("").setId("@slideshowsItems.delete");

              apiRouter.group("/action", function (apiRouter) {
                apiRouter.post("/restore").setId("@slideshowsItems.restore");
              });
            });
          });
        });
      });

      // Campaigns
      apiRouter.group("/campaigns", function (apiRouter) {
        apiRouter.get("").setId("@campaigns.viewAny");

        apiRouter.post("").setId("@campaigns.create");

        apiRouter.group("/<campaignId>", function (apiRouter) {
          apiRouter.setConstraint("campaignId", /\d+/);

          apiRouter.get("").setId("@campaigns.show");

          apiRouter.post("").setId("@campaigns.update");

          apiRouter.delete("").setId("@campaigns.delete");

          apiRouter.group("/action", function (apiRouter) {
            apiRouter.post("/restore").setId("@campaigns.restore");
          });
        });

        // Campaigns/Categories
        apiRouter.group("/categories", function (apiRouter) {
          apiRouter.get("").setId("@campaignsCategories.viewAny");

          apiRouter.post("").setId("@campaignsCategories.create");

          apiRouter.group("/<campaignsCategoryId>", function (apiRouter) {
            apiRouter.setConstraint("campaignsCategoryId", /\d+/);

            apiRouter.get("").setId("@campaignsCategories.show");

            apiRouter.post("").setId("@campaignsCategories.update");

            apiRouter.post("").setId("@campaignsCategories.delete");

            apiRouter.group("/action", function (apiRouter) {
              apiRouter
                .get("/autocomplete")
                .setId("@campaignsCategories.autocomplete");
              apiRouter.group("/restore", function (apiRouter) {
                apiRouter.post("").setId("@campaignsCategories.restore");
              });
            });
          });
        });
      });
    });

    // saml
    apiRouter.group("/saml", (apiRouter) => {
      apiRouter.group("/sso", (apiRouter) => {
        apiRouter.get("").setId("@samlSso.view");
      });
    });

    apiRouter.group("/auth", function (apiRouter) {
      apiRouter.post("/login").setId("@auth.login");

      apiRouter.post("/me").setId("@auth.me");

      apiRouter.post("/logout").setId("@auth.logout");
    });

    apiRouter.group("/sanctum", function (apiRouter) {
      apiRouter.group("/csrf-cookie", function (apiRouter) {
        apiRouter.get("").setId("@sanctum.csrfCookie");
      });
    });

    apiRouter.group("/laravel", function (apiRouter) {
      apiRouter.get("/telescope").setId("@laravel.telescope");

      apiRouter.get("/horizon").setId("@laravel.horizon");
    });

    // Places
    apiRouter.group("/places", function (apiRouter) {
      apiRouter.group("/<placeId>", function (apiRouter) {
        apiRouter.setConstraint("placeId", /\d+/);
        apiRouter.get("").setId("@places.show");
      });
      apiRouter.group("/action", function (apiRouter) {
        apiRouter.get("/autocomplete").setId("@places.autocomplete");
      });
    });
  },
);

export default apiRouter;
