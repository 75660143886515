// helpers
import nextRedirect from "../redirect";

// utils
import parseQuery from "@splitfire-agency/raiden-library/dist/libraries/utils/parseQuery";

// constants
import { USERS_USER_TYPE_VALUE_ADMIN } from "../../../constants/users";

/**
 * @typedef {object} Redirections
 * @property {string} signedIn
 * @property {string} signedOut
 */
/**
 * Récupère la layout à afficher ou effectuer une redirection si nécessaire.
 * L’utilisateur sera redirigé vers le bon layout si besoin (à condition qu’il n’y ait pas d’erreur de type rate-limiter).
 * @template {string} T
 * @param {object} param0
 * @param {Exclude<T, undefined>} [param0.pageLayout]
 * @param {import("http").ServerResponse} [param0.res]
 * @param {boolean} param0.tooManyRequest
 * @param {boolean} param0.logged
 * @param {object} param0.user
 * @param {Redirections} param0.redirections
 * @param {import("@splitfire-agency/raiden-library/dist/libraries/UriImmutable").default} param0.uri
 * @returns {Promise<Exclude<T | null, undefined>>}
 */
export default async function nextGetAdminLayout({
  pageLayout,
  res,
  tooManyRequest,
  logged,
  user,
  redirections,
  uri,
}) {
  /** @type {T | null} */
  let layout = null;

  const query = parseQuery(uri.getQuery() ?? "");

  if (user?.user_type === USERS_USER_TYPE_VALUE_ADMIN && query?.SAMLRequest) {
    layout = "signedSaml";
  } else if (
    "signedIn" === pageLayout &&
    (!logged || USERS_USER_TYPE_VALUE_ADMIN !== user?.user_type) &&
    !tooManyRequest &&
    res
  ) {
    await nextRedirect({
      code: 302,
      redirectionUri: redirections.signedOut,
      res,
    });
  } else if (
    "signedOut" === pageLayout &&
    USERS_USER_TYPE_VALUE_ADMIN === user?.user_type &&
    !tooManyRequest &&
    res
  ) {
    await nextRedirect({
      code: 302,
      redirectionUri: redirections.signedIn,
      res,
    });
  } else if ("indifferent" === pageLayout) {
    layout = logged ? "signedIn" : "signedOut";
  } else {
    layout = tooManyRequest ? "signedOut" : pageLayout;
  }

  return layout;
}
