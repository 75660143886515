// deps
// eslint-disable-next-line import/no-unresolved
import Router from "@splitfire-agency/raiden-library/dist/libraries/RouterImplementation/Router";
// eslint-disable-next-line import/no-unresolved
import Uri from "@splitfire-agency/raiden-library/dist/libraries/Uri";

const extranetRouter = new Router();

export const extranetBaseUri = new Uri(
  process.env.NEXT_PUBLIC_EXTRANET_SCHEME ?? "",
  null,
  process.env.NEXT_PUBLIC_EXTRANET_HOST,
  null,
  process.env.NEXT_PUBLIC_EXTRANET_BASE_PATH,
  null,
  null,
);

extranetRouter.group(extranetBaseUri, function (extranetRouter) {
  extranetRouter.get("").setId("@extranet");
});

export default extranetRouter;
