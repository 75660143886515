// deps
import { createContext, memo, useContext, useMemo, useState } from "react";
import PropTypes from "prop-types";
import cookie from "cookie";

// libraries
import getBasePath from "@splitfire-agency/raiden-library/dist/libraries/utils/getBasePath";

// constants
import { COOKIES_NAME_VALUE_DEFAULT_ENVIRONMENTS } from "@raiden/library-ui/constants/cookies";

// contexts
import { usePreferences } from "@raiden/library-ui/contexts/Preferences";

/**
 * @typedef {object} DefaultEnvironmentsContextValue
 * @property {string[]} defaultEnvironments
 * @property {(defaultEnvironment: any) => void} setDefaultEnvironments
 */

export const DefaultEnvironmentsContext = createContext(
  /**
   * @type {DefaultEnvironmentsContextValue}
   **/ ({
    defaultEnvironments: [],
    setDefaultEnvironments: () => {},
  }),
);

const { Provider } = DefaultEnvironmentsContext;

export const DefaultEnvironmentsProvider = memo(
  /**
   * @typedef {object} Props
   * @property {Record<string, any>} [cookies]
   * @property {React.ReactNode} children
   */
  /**
   * @param {Props} props
   */
  function ({ cookies, children }) {
    const { bestEnvironment } = usePreferences();

    let {
      [COOKIES_NAME_VALUE_DEFAULT_ENVIRONMENTS]: initialDefaultEnvironment = "",
    } = cookies;

    initialDefaultEnvironment = initialDefaultEnvironment
      ? initialDefaultEnvironment.split(",")
      : [];

    if (initialDefaultEnvironment.length === 0 && bestEnvironment) {
      initialDefaultEnvironment = [String(bestEnvironment.id)];
    }

    const [defaultEnvironments, setDefaultEnvironments] = useState(
      initialDefaultEnvironment,
    );

    const value = useMemo(() => {
      return {
        defaultEnvironments,
        setDefaultEnvironments: function (defaultEnvironment) {
          setDefaultEnvironments(defaultEnvironment);

          document.cookie = cookie.serialize(
            COOKIES_NAME_VALUE_DEFAULT_ENVIRONMENTS,
            defaultEnvironment,
            {
              path: getBasePath(process.env.NEXT_PUBLIC_ADMIN_BASE_PATH ?? ""),
              secure: true,
            },
          );
        },
      };
    }, [defaultEnvironments]);

    return <Provider value={value}>{children}</Provider>;
  },
);

DefaultEnvironmentsProvider.displayName = "DefaultEnvironmentsProvider";

DefaultEnvironmentsProvider.propTypes = {
  children: PropTypes.node,
};

export const useDefaultEnvironments = function () {
  return useContext(DefaultEnvironmentsContext);
};
