// helpers
import usersIsRoot from "../isRoot";

/**
 * Retourne vrai si l’authorisation comparée est présente dans la liste des authorisations.
 */
function hasAuthorization({ requestedAuthorizations, authorizations }) {
  return requestedAuthorizations.every(function (requestedAuthorization) {
    return authorizations.includes(requestedAuthorization);
  });
}

/**
 * Retourne vrai si l’utilisateur a le droit d’effectuer cette action.
 * @param {object} param0
 * @param {import("../../../types/User").UserBase} [param0.user]
 * @param {Array | string} param0.authorizations
 * @param {string | number} [param0.environmentId]
 */
export default function usersCan({ user, authorizations, environmentId }) {
  //  si le user est root on bypass tout ça?
  if (usersIsRoot({ user })) {
    return true;
  }

  if (
    /* Si le paramètre n’est pas un tableau */
    !Array.isArray(authorizations)
  ) {
    authorizations = [authorizations];
  }

  const userAuthorizations = user?.meta?.authorizations ?? [];

  if (
    /* Si on ne filtre pas par environnement. */
    !environmentId
  ) {
    return userAuthorizations.some(function (authorizationsByEnvironment) {
      return hasAuthorization({
        requestedAuthorizations: authorizations,
        authorizations: authorizationsByEnvironment?.authorizations ?? [],
      });
    });
  } else {
    if ("string" === typeof environmentId) {
      environmentId = parseInt(environmentId);
    }

    const userAuthorizationsAnyEnvironment = userAuthorizations.reduce(
      function (userAuthorizationsAnyEnvironment, environment) {
        if (
          environment.environment_id === environmentId ||
          "*" === environment.environment_id
        ) {
          userAuthorizationsAnyEnvironment.push(
            ...(environment?.authorizations ?? []),
          );
        }

        return userAuthorizationsAnyEnvironment;
      },
      [],
    );

    return hasAuthorization({
      requestedAuthorizations: authorizations,
      authorizations: userAuthorizationsAnyEnvironment,
    });
  }
}
